export function infoboxLinksIcon() {
  var accordion = jQuery('.info-box__links__icon');

  if (accordion.length > 0) {
    accordion = (function () {
      var $accordion = jQuery('.js-info-box__links__icon');
      var $accordion_item = $accordion.find('.js-info-box__links__icon__item');
      var $accordion_toggle = $accordion.find('.js-toggle');
      var $accordion_body = $accordion.find('.js-info-box__links__icon__item__body');

      // default settings
      var settings = {
        speed: 400,
        oneOpen: false
      };

      return {
        init: function ($settings) {
          // Enable the buttons
          $accordion_toggle.attr('disabled', false);

          // Remove existing click event and add a new one
          $accordion_toggle.off('click').on('click', function (e) {
            e.preventDefault();

            // Pass the toggle button to the toggle function
            accordion.toggle(jQuery(this));
          });

          // Extend default settings with any provided settings
          $.extend(settings, $settings);

          // Show active accordion bodies by default
          jQuery('.js-info-box__links__icon__item.active')
            .find('> .js-info-box__links__icon__item__body')
            .show();
        },

        toggle: function ($toggleBtn) {
          // Toggle aria-expanded attribute for accessibility
          var currentAriaExpanded = $toggleBtn.attr("aria-expanded");
          var newAriaExpanded = currentAriaExpanded === "true" ? "false" : "true";
          $toggleBtn.attr("aria-expanded", newAriaExpanded);

          // Find the corresponding accordion item and toggle its 'active' class
          var $accordionItem = $toggleBtn.closest($accordion_item);
          $accordionItem.toggleClass('active');

          // Slide toggle the body associated with this item
          var $body = $accordionItem.find('> .js-info-box__links__icon__item__body');
          $body.stop().slideToggle(settings.speed);

          // If only one item should be open at a time, close others
          if (settings.oneOpen) {
            $accordion_item.not($accordionItem).removeClass('active');
            $accordion_item.not($accordionItem)
              .find('> .js-info-box__links__icon__item__body')
              .stop()
              .slideUp(settings.speed);
          }
        },

        destroy: function () {
          // Disable the buttons on larger screens
          $accordion_toggle.off('click').attr('disabled', true); // Disable the buttons

          // Reset the accordion items
          $accordion_item.find('> .js-info-box__links__icon__item__body').hide();
          $accordion_item.removeClass('active');
          $accordion_item.find('> .js-info-box__links__icon__item__body').css({ height: "auto", display: "" });
          $accordion_item.find('> .js-info-box__links__icon__item__body').show();
        }
      };
    })();

    jQuery(window).on("load resize", function () {
      if (this.matchMedia("(max-width: 991px)").matches) {
        accordion.init({
          speed: 300,
          oneOpen: true
        });
      }

      if (this.matchMedia("(min-width: 991px)").matches) {
        accordion.destroy();
      }
    });
  }
}

export default infoboxLinksIcon;
